/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link, useStaticQuery, graphql } from "gatsby";
import Logo from "../../static/img/Logo-Hausarzt-Signer.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          herosection {
            address
            imageObject {
              alt
              image {
                childImageSharp {
                  fluid {
                    srcSet
                  }
                }
              }
              title
            }
            subtitle
            telephone
            title
          }
          news
        }
      }
    }
  `);
  const newsAvailable = data.markdownRemark.frontmatter.news !== "empty";
  return (
    <header
      id="Start"
      sx={{
        display: "flex",
        margin: "0 auto",
        justifyContent: "center",
        px: [3, 4, 5],
        pb: 5,
        pt: [2, 3, 4],
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1000px",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: ["column", "row", "row"],
            justifyContent: "space-between",
            maxWidth: "1000px",
          }}
        >
          <Link to="/#Start" sx={{ width: ["50%", "30", "35%"], mr: 3 }}>
            <div sx={{ width: ["100%", "100%", "100%"], height: "auto" }}>
              <Logo
                sx={{ width: "100%", height: "100%", cursor: "pointer" }}
                alt={"Hausarzt Signer Lappersdorf Logo"}
                title={"Hausarzt Signer Lappersdorf Logo"}
              ></Logo>
            </div>
          </Link>
          <div
            sx={{
              height: "auto",
              display: "flex",
              justifyContent: ["space-between", "flex-end", "flex-end"],
              alignItems: "center",
            }}
          >
            <a
              href="https://goo.gl/maps/f3f6vaCNTMPmi4Cy8"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                pt: [2, 0, 0],
              }}
            >
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                sx={{ color: "primary", fontSize: [4, 5, 6] }}
              />
              <span
                sx={{
                  color: "muted",
                  fontWeight: "text",
                  fontSize: [1, 2, 3],
                  lineHeight: 1,
                  pl: [2, 2, 3],
                }}
              >
                Regensburger Str. 21 <br /> 93138 Lappersdorf
              </span>
            </a>
            <a
              href="tel:+4994181220"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                pt: [2, 0, 0],
              }}
            >
              <FontAwesomeIcon
                icon={faPhone}
                sx={{ color: "primary", fontSize: [3, 4, 5], ml: [0, 3, 3] }}
              />
              <span
                sx={{
                  color: "muted",
                  fontWeight: "bold",
                  fontSize: [1, 2, 3],
                  lineHeight: 1,
                  pl: [2, 2, 3],
                }}
              >
                {data.markdownRemark.frontmatter.herosection.telephone}
              </span>
            </a>
          </div>
        </div>
        <nav
          sx={{
            width: "auto",
            height: "auto",
            pt: 4,
            display: ["none", "flex", "flex"],
            justifyContent: "space-between",
          }}
        >
          {newsAvailable && (
            <Link to="/#Aktuelles" sx={{ variant: "navlink" }}>
              Aktuelles
            </Link>
          )}
          <Link to="/#Profil" sx={{ variant: "navlink" }}>
            Profil
          </Link>
          <Link to="/#Praxisteam" sx={{ variant: "navlink" }}>
            Praxisteam
          </Link>
          <Link to="/#Leistungen" sx={{ variant: "navlink" }}>
            Leistungen
          </Link>
          <Link to="/#Kontakt" sx={{ variant: "navlink" }}>
            Kontakt
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
