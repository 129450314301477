/** @jsx jsx */
import { jsx } from "theme-ui";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

//import CookieConsent from "react-cookie-consent";

const TemplateWrapper = ({ children }) => {
  return (
    <div>
      <Navbar />
      <main
        sx={{
          color: "#2c2a29",
        }}
      >
        {children}
      </main>
      <Footer />
      {/* <CookieConsent
        cookieName="gatsby-gdpr-google-analytics"
        location="bottom"
        buttonText="Akzeptieren"
        enableDeclineButton
        declineButtonText="Ablehnen"
        buttonStyle={{
          backgroundColor: "#7CB951",
          padding: "0.5rem",
          color: "#ffffff",
          border: "1px solid #7CB951", 
        }}
        declineButtonStyle={{
          backgroundColor: "#353535",
          padding: "0.5rem",
          color: "#ffffff",
          border: "1px solid #ffffff",
        }}
        style={{
          bg: "black",
          padding: "0.5rem",
        }}
        expires={730}
      >
        Diese Webseite nutzt einen anonymisierten Statistik Cookie.{" "}
        <Link
          sx={{ textDecoration: "underline", color: "background" }}
          to="/datenschutz"
        >
          Datenschutz.
        </Link>
      </CookieConsent> */}
    </div>
  );
};

export default TemplateWrapper;
