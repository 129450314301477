/** @jsx jsx */
import { jsx } from "theme-ui";
import Logo from "../../static/img/logo-weiß.svg";

const Footer = () => {
  return (
    <footer
      sx={{
        bg: "muted",
        display: "flex",
        justifyContent: "center",
        pb: 4,
        mt: 6,
        pt: 3,
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo
          sx={{ height: [6, 7, 7], width: [6, 7, 7] }}
          title="Hausarzt Praxis Christian Signer"
          alt="Hausarzt Praxis Christian Signer Logo"
        />
        <div
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <a
            sx={{
              fontSize: [2, 3, 3],
              px: "0px",
              mx: "0px",
              textAlign: "center",
              textDecoration: "none",
              textDecorationColor: "white",
              color: "white",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            href="/impressum"
          >
            Impressum
          </a>
          <a
            sx={{
              fontSize: [2, 3, 3],
              px: "0px",
              mx: "0px",
              textAlign: "center",
              textDecoration: "none",
              textDecorationColor: "white",
              color: "white",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            href="/datenschutz"
          >
            Datenschutz
          </a>
        </div>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <span
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: 1,
              textDecoration: "none",
            }}
          >
            2020 © Christian Signer
          </span>
          <a
            sx={{
              color: "white",
              textAlign: "center",
              fontSize: 1,
              textDecoration: "none",
              textDecorationColor: "#d84848",
              "&:hover": {
                textDecorationLine: "underline",
              },
            }}
            href="https://agenturignis.de"
          >
            Webdesign & Betreuung: Agentur ignis
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
